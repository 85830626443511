@import "variables";
//@import "../site/style/components/hh-btn";

.panel {
  margin-top: 25px;
  padding: 5rem;
}

.black-title {
  font-family:Circular Std;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  text-align: left;
  letter-spacing: -0.01em;
  color: #000000;
  margin-left: 15px;
}

.login-subtitle {
  font-family: Circular Std;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 15px;
  color: #A5A5A5;
  margin-left: 15px;
}

.btn-facebook, .btn-google{
  background: #FFFFFF;
  border: 1px solid #EBEBEC;
  box-sizing: border-box;
  border-radius: 8px; color: #1F1F1F; 
  font-family: Circular Std;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left; width: 100%; padding: 1.2rem;
}

.or {
  font-family: Circular Std;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 15px;
  color: #B9B9B9;
}

.input-label {
  text-align: left !important; 
  margin-bottom: 10px !important; 
  font-family: Circular Std !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: -0.01em !important;
  color: #3D3D3D !important; 
  display: flex;
  input {
    margin-top: 0px !important;
    margin-right: 5px;
  }
}

.email-placeholder, .password-placeholder {
  height: 55px; font-family: Circular Std;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.01em; padding-left: 4rem;
  color: black;    
}

  .email-placeholder {
  background-image:url(/images/email.svg); 
  background-repeat: no-repeat; 
  background-position: 10px 18px;
}

.password-placeholder {
  background-image:url(/images/password.svg); 
  background-repeat: no-repeat; 
  background-position: 10px 18px;
}

.checkbox {
  border: 1px solid #898989;
  box-sizing: border-box;
  border-radius: 4px;
}

.login-btn {
  width: 100% !important; height: 56px; font-family: Circular Std;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.forgot-password, .no-account {
  font-family: Circular Std;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.01em;
}

.form-separator {
  height: 12px;
  margin: 26px 0 32px;
  text-align: center;
  border-bottom: 2px solid #F5F7FA;
  .or {
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 8px;
    background-color: #fff;
    color: #333333;
  }
}



.forgot-password {
  color: #777777;
}

.no-account {
  color: #B3B3B3;
  span {
    color: #1DC071;
  }
}

.panel.panel-default.row {
  padding: 5rem;
}


@media screen and (max-width: 767px) {
  .panel.panel-default.row {
    padding: 1rem !important;
  }
}

#login-modal {
  width: 100%;
  max-width: 635px;
}